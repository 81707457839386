@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&family=Oswald:wght@400;700&display=swap');

/* Beer Menu Styles */

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

a, abbr, acronym, address, applet, b, big, blockquote, body, center, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, tt, u, ul, var {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	vertical-align: baseline;
	background: 0 0;
}

body {
	font-family: Open Sans,Arial,sans-serif;
	font-size: 14px;
	color: #666;
	line-height: 1.7em;
	font-weight: 500;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
	color: #333;
	padding-bottom: 10px;
	line-height: 1em;
	font-weight: 500;
}

h1, h2, h3, h4, h5, h6, .et_quote_content blockquote p, .et_pb_slide_description .et_pb_slide_title {
	font-weight: 700;
	font-style: normal;
	text-transform: none;
	text-decoration: none;
}

h2 {
	font-size: 26px;
}

a, a:hover {
  color: #f48326;
	text-decoration: none;
}

article, aside, footer, header, hgroup, nav, section {
	display: block;
}


#left-area ul, .entry-content ul, .et-l--body ul, .et-l--footer ul, .et-l--header ul {
	list-style-type: disc;
	padding: 0 0 23px 1em;
	line-height: 26px;
}

:root {
  --brown: #80561b;
  --light-gray: #f2f2f2;
  --orange: rgb(244, 131, 38);
  --padding-standard: 1rem;
  --font-size-standard: 1.4rem;
  --font-size-small: 0.775rem;
}

#beer-menu-content {
  width: 80%;
  margin: auto;
}

@media (max-width: 768px) {
  #beer-menu-content {
    width: 100%;
  }
}

header .heading {
  background-color: var(--brown);
  padding-top: 5vw;
  padding-bottom: 5vw;
}

header .subheading {
  margin-bottom: 5vw;
  padding-bottom: 0;
  padding-top: 5vw;
}

header h1 {
  font-size: 8vw;
  color: #fff;
  text-align: center;
  font-family: 'Oswald', Helvetica, Arial, Lucida, sans-serif;
}

header h3 {
  font-family: 'Oswald', Helvetica, Arial, Lucida, sans-serif;
  text-transform: uppercase;
  font-size: 3vw;
  color: var(--orange);
  line-height: 1.2em;
  text-align: center;
  margin-bottom: 1.5%;
}

header p {
  text-align: center;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  color: #666;
  line-height: 1.7em;
  font-weight: 500;
  font-size: 1rem;
}

#beer-menu-content {
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  font-size: 100%;
}

.beer-menu-nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  text-align: center;
}

.beer-menu-nav li {
  flex: 1;
  background-color: var(--light-gray);
  color: var(--brown);
  font-size: var(--font-size-standard);
  text-transform: uppercase;
  font-weight: 900;
}

.beer-menu-nav li:hover {
  background-color: #d9d9d9; /* darken equivalent */
}

.beer-menu-nav li a {
  display: block;
  padding-bottom: var(--padding-standard);
  padding-top: var(--padding-standard);
  color: var(--brown);
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.beer-menu-nav li a::after {
  display: inline-block;
  content: "\2023";
  font-size: 2rem;
}

.beer-menu-nav li a.activeNavLink {
  background-color: var(--orange);
  color: #fff;
}

.beer-menu-nav li a.activeNavLink::after {
  transform: rotate(90deg);
}

.beer-menu-name {
  text-align: center;
  background-color: var(--orange);
  color: #fff;
  padding: 58px 0;
}

.beer-menu-name h2 {
  text-transform: uppercase;
  color: #fff;
  font-family: 'Oswald', Helvetica, Arial, Lucida, sans-serif;
}

.beer-menu-section {
  color: var(--orange);
  font-size: var(--font-size-standard);
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #b3b3b3;
}

.beer-menu-section__arrow {
  font-size: 2rem;
  color: var(--brown);
  padding-right: 16px;
}

.accordion__button {
  cursor: pointer;
  padding: 28px 0 28px 15px;
  width: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
}

.accordion__button:hover {
  background-color: #fef3e9; /* color.scale equivalent */
}

.accordion__button[aria-expanded="true"] {
  background-color: var(--orange);
  color: #fff;
}

[hidden] {
  display: none;
}

.accordion__panel {
  animation: fadein 0.2s ease-in;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.beer-menu-item {
  background-color: var(--light-gray);
  border-bottom: 1px solid #b3b3b3;
  color: var(--brown);
  display: flex;
  margin: 0;
  margin-right: 5px;
  font-size: 1.1rem;
  padding: 18px 10px 18px 15px;
  font-weight: 700;
}

.beer-menu-item__detail {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-small);
  font-weight: normal;
}

/* .beer-menu-item__meta {
  font-size: 0.8rem;
} */

.beer-menu-item__meta.beer-info {
	flex: 50%;
}

.beer-menu-item__meta.beer-style {
	flex: 22%;
}

.beer-menu-item__meta.beer-abv {
	flex: 10%;
}

.beer-menu-item__meta.beer-prices {
	flex: 18%;
}

@media (max-width: 1224px) {
  #beer-menu-content {
    font-size: 75%;
  }

  nav.beer-menu-nav ul li a {
    font-size: 1.2rem;
  }

  .beer-menu-item__meta {
    font-size: 0.8rem;
  }
}
